.icon.dropdown-more {
  background-image: url("../../../public/assets/images/icons/dropdown-more.svg");
}

button.item.dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}

button.item.dropdown-item-active i {
  opacity: 1;
}

.dropdown {
  z-index: 100;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
  min-width: unset;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important;
  }

  .dialog-dropdown > .dropdown-button-text {
    display: flex !important;
  }

  .font-size .dropdown-button-text {
    display: flex !important;
  }

  .code-language .dropdown-button-text {
    display: flex !important;
  }
}

i.palette {
  background-image: url("../../../public/assets/images/icons/palette.svg");
}

i.bucket {
  background-image: url("../../../public/assets/images/icons/paint-bucket.svg");
}

i.bold {
  background-image: url("../../../public/assets/images/icons/type-bold.svg");
}

i.italic {
  background-image: url("../../../public/assets/images/icons/type-italic.svg");
}

i.clear {
  background-image: url("../../../public/assets/images/icons/trash.svg");
}

i.code {
  background-image: url("../../../public/assets/images/icons/code.svg");
}

i.underline {
  background-image: url("../../../public/assets/images/icons/type-underline.svg");
}

i.strikethrough {
  background-image: url("../../../public/assets/images/icons/type-strikethrough.svg");
}

i.subscript {
  background-image: url("../../../public/assets/images/icons/type-subscript.svg");
}

i.superscript {
  background-image: url("../../../public/assets/images/icons/type-superscript.svg");
}

i.link {
  background-image: url("../../../public/assets/images/icons/link.svg");
}

i.horizontal-rule {
  background-image: url("../../../public/assets/images/icons/horizontal-rule.svg");
}

.icon.plus {
  background-image: url("../../../public/assets/images/icons/plus.svg");
}

.icon.caret-right {
  background-image: url("../../../public/assets/images/icons/caret-right-fill.svg");
}

.icon.font-color {
  background-image: url("../../../public/assets/images/icons/font-color.svg");
}

.icon.font-family {
  background-image: url("../../../public/assets/images/icons/font-family.svg");
}

.icon.bg-color {
  background-image: url("../../../public/assets/images/icons/bg-color.svg");
}

.icon.table {
  background-color: #6c757d;
  mask-image: url("../../../public/assets/images/icons/table.svg");
  -webkit-mask-image: url("../../../public/assets/images/icons/table.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
}

i.image {
  background-image: url("../../../public/assets/images/icons/file-image.svg");
}

i.table {
  background-image: url("../../../public/assets/images/icons/table.svg");
}

i.close {
  background-image: url("../../../public/assets/images/icons/close.svg");
}

i.figma {
  background-image: url("../../../public/assets/images/icons/figma.svg");
}

i.poll {
  background-image: url("../../../public/assets/images/icons/card-checklist.svg");
}

i.columns {
  background-image: url("../../../public/assets/images/icons/3-columns.svg");
}

i.tweet {
  background-image: url("../../../public/assets/images/icons/tweet.svg");
}

i.youtube {
  background-image: url("../../../public/assets/images/icons/youtube.svg");
}

.icon.left-align,
i.left-align {
  background-image: url("../../../public/assets/images/icons/text-left.svg");
}

.icon.center-align,
i.center-align {
  background-image: url("../../../public/assets/images/icons/text-center.svg");
}

.icon.right-align,
i.right-align {
  background-image: url("../../../public/assets/images/icons/text-right.svg");
}

.icon.justify-align,
i.justify-align {
  background-image: url("../../../public/assets/images/icons/justify.svg");
}

i.indent {
  background-image: url("../../../public/assets/images/icons/indent.svg");
}

i.markdown {
  background-image: url("../../../public/assets/images/icons/markdown.svg");
}

i.outdent {
  background-image: url("../../../public/assets/images/icons/outdent.svg");
}

i.undo {
  background-image: url("../../../public/assets/images/icons/arrow-counterclockwise.svg");
}

i.redo {
  background-image: url("../../../public/assets/images/icons/arrow-clockwise.svg");
}

i.sticky {
  background-image: url("../../../public/assets/images/icons/sticky.svg");
}

i.mic {
  background-image: url("../../../public/assets/images/icons/mic.svg");
}

i.import {
  background-image: url("../../../public/assets/images/icons/upload.svg");
}

i.export {
  background-image: url("../../../public/assets/images/icons/download.svg");
}

i.share {
  background-image: url("../../../public/assets/images/icons/send.svg");
}

i.diagram-2 {
  background-image: url("../../../public/assets/images/icons/diagram-2.svg");
}

i.user {
  background-image: url("../../../public/assets/images/icons/user.svg");
}

i.equation {
  background-image: url("../../../public/assets/images/icons/plus-slash-minus.svg");
}

i.gif {
  background-image: url("../../../public/assets/images/icons/filetype-gif.svg");
}

i.copy {
  background-image: url("../../../public/assets/images/icons/copy.svg");
}

i.success {
  background-image: url("../../../public/assets/images/icons/success.svg");
}

i.prettier {
  background-image: url("../../../public/assets/images/icons/prettier.svg");
}

i.prettier-error {
  background-image: url("../../../public/assets/images/icons/prettier-error.svg");
}

i.page-break,
.icon.page-break {
  background-image: url("../../../public/assets/images/icons/scissors.svg");
}

.icon.paragraph {
  background-image: url("../../../public/assets/images/icons/text-paragraph.svg");
}

.icon.h1 {
  background-image: url("../../../public/assets/images/icons/type-h1.svg");
}

.icon.h2 {
  background-image: url("../../../public/assets/images/icons/type-h2.svg");
}

.icon.h3 {
  background-image: url("../../../public/assets/images/icons/type-h3.svg");
}

.icon.h4 {
  background-image: url("../../../public/assets/images/icons/type-h4.svg");
}

.icon.h5 {
  background-image: url("../../../public/assets/images/icons/type-h5.svg");
}

.icon.h6 {
  background-image: url("../../../public/assets/images/icons/type-h6.svg");
}

.icon.bullet-list,
.icon.bullet {
  background-image: url("../../../public/assets/images/icons/list-ul.svg");
}

.icon.check-list,
.icon.check {
  background-image: url("../../../public/assets/images/icons/square-check.svg");
}

.icon.numbered-list,
.icon.number {
  background-image: url("../../../public/assets/images/icons/list-ol.svg");
}

.icon.quote {
  background-image: url("../../../public/assets/images/icons/chat-square-quote.svg");
}

.icon.code {
  background-image: url("../../../public/assets/images/icons/code.svg");
}