body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPaper-root + .MuiToolbar-root {
  min-height: 48px !important;
}

@media (min-width: 600px) {
  .app-layout-toolbar {
    min-height: 48px !important;
  }
}

.plus-icon {
  fill: none !important;
}

.plus-icon .stroke {
  transition: all 0.4s ease;
}

.plus-icon:hover .stroke {
  stroke-opacity: 0.6;
}
